//// Only very important style here

/*fix scroll lock on ios12.2*/
ion-app.platform-ios12 ion-content {
    pointer-events: auto;
}

ion-app.platform-ios12 ion-navbar {
    pointer-events: auto;
}
ion-app ion-content {
    pointer-events: auto;
}
ion-app ion-navbar {
    pointer-events: auto;
}

// page best fit 
#ion-page-best-fit{
    width: 375px;
    height: 667px;
    margin: auto;
    box-shadow: 0 4px 16px rgba(0,0,0,.12);
}

.full-btn-shadow{
    --box-shadow: 0 0 2rem 0.1rem #ccc;
}
.key-btn-box{
    --background: var(--ion-color-success);
    --background-activated: var(--ion-color-success-shade);
    --background-focused: var(--ion-color-success-shade);
    .item-count-chip{
        background: var(--ion-color-success-shade);
        color: var(--ion-color-success-constrast);
    }
}
ion-toolbar{
    --background: var(--ion-color-primary);
    --color: var(--ion-color-primary-contrast);
}

//// toast
.error-alert{
    --background: var(--ion-color-danger);
    --color: #fff;
}

//// payment page share componnent

.payment-info{
    .upper-wrapper{
        text-align: center;
        p:first-child{
            font-size: var(--main-mid-text);
            margin-bottom: .5em;
        }
        p:last-child{
            font-size: var(--main-xlg-text);
            font-weight: bold;
            margin-top: 0;
        }
    }
}

// wechat pay
.wechat-pay-btn{
    ion-button{
        --background: #43c118;
    }
}

// alipay
.alipay-pay-btn{
    ion-button{
        --background: #47ace9;
    }
}

//// toast
.error-alert{
    --background: var(--ion-color-danger);
    --color: #fff;
}

//// toast controller ////
  ion-toast.add-item-toast{
    --background: #f6ffed;
    --border-color: #b7eb8f;
    --border-style: solid;
    --boder-width: 1px;
    height: calc(100% - 55px) !important;
}

/// modal
.modal-wrapper{
    --border-radius: 16px;
}

//image-box
.img-box{
    position: relative;
    //width: 100%;
    overflow: hidden;
    //background: url(/assets/image/no-image.jpg) no-repeat center !important;
    //background-size: cover !important;
    // background-color: lightgrey;
    background: transparent;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;

}
.img-box:before{
    content: "";
    display: block;
    padding-top: 100%;
}

.img-box img{
    object-fit: cover;
    object-position: center;
    position:  absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: white;
    text-align: center;
    width: 100%;
}
